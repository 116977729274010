<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          Users
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CInput 
              placeholder="Username"
              v-model="cariUsername">
              <template #append>
                <CButton color="primary" @click="CariUser">Cari</CButton>
              </template>
              </CInput>
            </CCol>
          </CRow>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
            <template #member_status="data">
              <td>
                <CBadge :color="getBadge(data.item.member_status)">
                  {{data.item.member_status}}
                </CBadge>
              </td>
            </template>
            <template #show_details="{item}">
            <td class="py-2">
              <router-link :to="{ name: 'Users Detail', params: { id: item.id}}">
              <CButton
                color="success"
                square
                size="sm"
              >
                Edit User
              </CButton>
              </router-link>
              <CButton
                color="warning"
                square
                size="sm"
                @click="updatePassword(item)"
              >
                Change Password
              </CButton>
              <CButton
                color="secondary"
                square
                size="sm"
                @click="updateStatus(item)"
              >
                Change Status
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      title="Change Password"
      color="warning"
      :show.sync="changePasswordModal"
    >
      <CForm @submit="submitChangePassword">
      <CRow>
        <CCol sm="6">
          <CInput
            label="Username"
            v-model="fieldupdatePassword.member_username"
            disabled
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="Member Name"
            v-model="fieldupdatePassword.member_name"
            disabled
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Password"
            v-model="fieldupdatePassword.password"
          />
        </CCol>
        <CCol sm="6">
          <CButton color="warning" square size="sm" @click="modalKembali">Cancel</CButton>
        </CCol>
        <CCol sm="6" align="right">
          <CButton color="success" type="submit" square size="sm">Submit</CButton>
        </CCol>
      </CRow>
      </CForm>
    </CModal>

    <CModal
      title="Change Status"
      color="warning"
      :show.sync="changeStatusModal"
    >
      <CForm @submit="submitChangeStatus">
      <CRow>
        <CCol sm="6">
          <CInput
            label="Username"
            v-model="fieldupdateStatus.member_username"
            disabled
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="Member Name"
            v-model="fieldupdateStatus.member_name"
            disabled
          />
        </CCol>
        <CCol sm="12">
          <CSelect
          label="Status"
          :value.sync="fieldupdateStatus.member_status"
          :options="pilihStatus"
          />
        </CCol>
        <CCol sm="6">
          <CButton color="warning" square size="sm" @click="modalKembaliStatus">Cancel</CButton>
        </CCol>
        <CCol sm="6" align="right">
          <CButton color="success" type="submit" square size="sm">Submit</CButton>
        </CCol>
      </CRow>
      </CForm>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Users',
  data () {
    return {
      urlApi:this.$store.state.urlApi,
      changePasswordModal:false,
      changeStatusModal:false,
      pilihStatus:["Pilih Status","Active","Suspended"],
      fieldupdatePassword:{
        id:null,
        member_username:null,
        member_name:null,
        password:null
      },
      fieldupdateStatus:{
        id:null,
        member_username:null,
        member_name:null,
        member_status:null
      },
      items: [],
      fields: [
        { key: 'member_username', label: 'Username', _classes: 'font-weight-bold' },
        { key: 'member_name', label: 'Member Name', },
        { key: 'member_mobile_no', label: 'Mobile No' },
        { key: 'member_email', label: 'Email' },
        { key: 'member_ktp_no', label: 'No Ktp' },
        { key: 'member_status', label: 'Status'  },
        { 
          key: 'show_details', 
          label: 'Action', 
          _style: 'width:30%', 
          sorter: false, 
          filter: false,
          align: 'center'
        }
      ],
      activePage: 1,
      cariUsername:null
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details`).then(response => {
      this.items = response.data
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Suspended': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    CariUser(){
      axios.get(`${this.urlApi}/api/user/getUserByUsername/${this.cariUsername}`).then(response => {
        this.items = [response.data]
      }).catch(function (error) {
        console.log(error);
      })
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    updatePassword(item){
      this.changePasswordModal = true
      this.fieldupdatePassword.id = item.id
      this.fieldupdatePassword.member_username = item.member_username
      this.fieldupdatePassword.member_name = item.member_name

    },
    updateStatus(item){
      this.changeStatusModal = true
      this.fieldupdateStatus.id = item.id
      this.fieldupdateStatus.member_username = item.member_username
      this.fieldupdateStatus.member_name = item.member_name
    },
    modalKembali(){
      this.fieldupdatePassword.id = ''
      this.fieldupdatePassword.member_username = ''
      this.fieldupdatePassword.member_name = ''
      this.fieldupdatePassword.password = ''
      
      this.changePasswordModal = false
      
    },
     modalKembaliStatus(){
      this.fieldupdateStatus.id = ''
      this.fieldupdateStatus.member_username = ''
      this.fieldupdateStatus.member_name = ''
      this.fieldupdateStatus.password = ''
      
      this.changeStatusModal = false
      
    },
    submitChangePassword(evt) {
      evt.preventDefault()

      axios.put(`${this.urlApi}/api/reset-password/update/${this.fieldupdatePassword.id}`,this.fieldupdatePassword).then(response => {
        location.reload()
      })
    },
    submitChangeStatus(evt){
      evt.preventDefault()

      axios.put(`${this.urlApi}/api/user-details/${this.fieldupdateStatus.id}`,this.fieldupdateStatus).then(response => {
        location.reload()
      })

    }
  }
}
</script>
